import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Dialog,
  IconButton,
  Grid,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import ModalItem from "./ModalItem";

interface IProps {
  open: boolean;
  handleClose: () => void;
  info: Object[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    floatingButton: {
      float: "right",
      color: theme.palette.secondary.main,
    },
    modalContent: {
      padding: "2em",
    },
    grid: {
      width: "100%",
    },
  }),
);

const Header = (props: IProps) => {
  const classes = useStyles();
  let title, photo;

  if (props.info) {
    title = props.info.find(
      (item) => item["data_name"] === "building_characteristics_building_name",
    );
    photo = props.info.find(
      (item) => item["data_name"] === "building_characteristics_photo",
    );
    if (!photo.value) {
      photo = undefined;
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 0 },
      }}
    >
      <div>
        <IconButton
          onClick={props.handleClose}
          className={classes.floatingButton}
          aria-label="Close modal"
        >
          <CancelIcon />
        </IconButton>
        <div className={classes.modalContent}>
          <Grid className={classes.grid} container>
            {title && (
              <ModalItem
                key={-1}
                type={title["data_name"]}
                label={title["label"]}
                value={title["value"]}
                photo={[]}
              />
            )}
            {photo && (
              <ModalItem
                key={-2}
                type={photo["data_name"]}
                label={photo["label"]}
                value={photo["value"]}
                photo={photo["value"]}
              />
            )}
            {props.info.map((item, i) => {
              if (
                item["value"] &&
                item["data_name"] !==
                  "building_characteristics_building_name" &&
                item["data_name"] !== "building_characteristics_photo"
              ) {
                return (
                  <ModalItem
                    key={i}
                    type={item["data_name"]}
                    label={item["label"]}
                    value={item["value"]}
                    photo={[]}
                  />
                );
              } else return null;
            })}
          </Grid>
        </div>
      </div>
    </Dialog>
  );
};

export default Header;
