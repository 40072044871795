import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
} from "@material-ui/core";

import Circle from "../../../charts/Circle";

interface IProps {
  data: object;
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rating: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    alignCenter: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  }),
);

const AreasAccordion = (props: IProps) => {
  const classes = useStyles();

  const filled = (value: string) => {
    return value === "Yes" ? true : false;
  };

  const find = (data, data_name) => {
    return data.find((item) => item["data_name"] === data_name).value;
  };

  return (
    <Grid container>
      <Grid item xs={5}>
        <Typography variant={"h6"}>
          {find(props.data, "area_area_type")}
        </Typography>
      </Grid>
      <Grid className={classes.alignCenter} item xs={2}>
        <Typography variant={"body2"}>
          {find(props.data, "area_use")}
        </Typography>
      </Grid>
      <Grid className={classes.alignCenter} item xs={2}>
        <Typography variant={"body2"}>
          {find(props.data, "area_surface_area_m_")}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <div className={classes.rating}>
          <Circle filled={filled(find(props.data, "area_conditioned_heat"))} />
          <Circle filled={filled(find(props.data, "area_conditioned_cold"))} />
          <Circle
            filled={filled(find(props.data, "area_conditioned_above_grade"))}
          />
          <Circle
            filled={filled(find(props.data, "area_conditioned_below_grade"))}
          />
        </div>
      </Grid>
    </Grid>
  );
};
export default AreasAccordion;
