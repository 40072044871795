import React from "react";
import { useSelector } from "react-redux";
import {
  createStyles,
  makeStyles,
  Theme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import DetailHandler from "./detail/detail-tabs/DetailHandler";
import SummaryHandler from "./detail/accordions-summary/SummaryHandler";
import HeaderHandler from "./detail/accordion-headers/HeaderHandler";

interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      backgroundColor: theme.palette.background.default,
      padding: "1.25em 2em",
      borderRight: `0.1875em solid ${theme.palette.secondary.main}`,
    },
    accordion: {
      backgroundColor: theme.palette.background.default,
      boxShadow: "none",
    },
    hover: {
      "&:hover": {
        cursor: "pointer",
        transition: "0.2s",
        "& h6": {
          color: theme.palette.secondary.main,
        },
      },
    },
    active: {
      "& h6": {
        color: theme.palette.secondary.main,
      },
    },
    open: {
      transition:
        "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
  }),
);

const ContentDetails = (props: IProps) => {
  const classes = useStyles();
  const accordions: string[] = ["Repeated", "Repeatable", "Section"];
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const state = useSelector((state) => state.activeSection);
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      {state.elements && (
        <HeaderHandler data_name={state.data_name} elements={state.elements} />
      )}
      {state.elements &&
        state.elements.map((option, i) => {
          if (accordions.includes(option.type) && option.elements) {
            return (
              <Accordion
                key={i}
                square={true}
                classes={{ expanded: classes.open }}
                className={classes.accordion}
                expanded={expanded === `panel${i}`}
                onChange={handleChange(`panel${i}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${i}`}
                  id={`panel${i}`}
                  className={classes.hover}
                  classes={{ expanded: classes.active }}
                >
                  <SummaryHandler
                    data_name={state.data_name}
                    data={option.elements}
                    title={
                      !(option.type === "Repeated")
                        ? option.label
                        : option.elements[0].value
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {option.elements && (
                    <DetailHandler
                      data={option.elements}
                      data_name={state.data_name}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            );
          } else return null;
        })}
    </div>
  );
};

export default ContentDetails;
