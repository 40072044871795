import React from "react";
import { createStyles, makeStyles, Theme, Grid } from "@material-ui/core";

import ComplexDetailItem from "../../ComplexDetailItem";

interface IProps {
  data: object[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  }),
);

const BuildingEnvelopeDetail = (props: IProps) => {
  const classes: Record<"root", string> = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {props.data[0]["type"] === "Repeated" &&
          props.data.map((element, i) => {
            return (
              <ComplexDetailItem
                title={element["label"]}
                key={i}
                item={element["elements"]}
              />
            );
          })}
      </Grid>
    </div>
  );
};
export default BuildingEnvelopeDetail;
