import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
} from "@material-ui/core";

import Circle from "../../../charts/Circle";

interface IProps {
  data: Object;
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rating: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    alignCenter: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  }),
);

const EfficiencyMeasuresAccordion = (props: IProps) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant={"h6"}>{props.data[0].value}</Typography>
      </Grid>
      <Grid className={classes.alignCenter} item xs={2}>
        <Typography variant={"body2"}>{props.data[1].value}</Typography>
      </Grid>
      <Grid item xs={2}>
        <div className={classes.rating}>
          <Circle filled={true} />
          {["Medium", "High"].includes(props.data[2].value[0]) ? (
            <Circle filled={true} />
          ) : (
            <Circle filled={false} />
          )}
          {props.data[2].value[0] === "High" ? (
            <Circle filled={true} />
          ) : (
            <Circle filled={false} />
          )}
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className={classes.rating}>
          <Circle filled={true} />
          {["Medium", "High"].includes(props.data[3].value[0]) ? (
            <Circle filled={true} />
          ) : (
            <Circle filled={false} />
          )}
          {props.data[3].value[0] === "High" ? (
            <Circle filled={true} />
          ) : (
            <Circle filled={false} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default EfficiencyMeasuresAccordion;
