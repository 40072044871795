import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { Amplify } from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import Topbar from "../common/Topbar";
import Wrapper from "./Wrapper";

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_REGION,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
  },
});

interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
    loading: {
      margin: "auto",
      width: "7rem",
      height: "7rem",
      "&::after": {
        content: "''",
        display: "block",
        width: "6rem",
        height: "6rem",
        borderRadius: "50%",
        border: `0.5rem solid ${theme.palette.secondary.main}`,
        borderColor: `${theme.palette.secondary.main} transparent ${theme.palette.secondary.main} transparent`,
        animation: `$load 1.4s linear infinite`,
      },
    },
    "@keyframes load": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
    err: {
      position: "absolute",
      top: "20vh",
      left: "50vw",
      transform: "translate(-55%, -50%)",
    },
  }),
);

const App = (props: IProps) => {
  const classes: Record<"root" | "loading" | "err", string> = useStyles();
  const [loaded, isLoaded] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [auth, setAuth] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object | undefined>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuth(nextAuthState);
      setUser(authData);
    });
  }, []);
  React.useEffect(() => {
    Axios.all([
      Axios.post(`https://api.dhub.arup.com/h2020/updateDB`),
      Axios.get(`https://api.dhub.arup.com/h2020/getData`),
    ])
      .then((response) => {
        dispatch({
          type: "SET_BUILDINGS_LIST",
          buildingsList: response[1].data,
        });
        dispatch({
          type: "SET_ACTIVE_BUILDING",
          active: response[1].data[0],
        });
        dispatch({
          type: "SET_ACTIVE_SECTION",
          section: response[1].data[0][1],
        });
        isLoaded(true);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [dispatch]);

  return auth === AuthState.SignedIn && user ? (
    <div className={classes.root}>
      {loaded && <Topbar /> && <Wrapper />}
      {error && <h3 className={classes.err}> {error} </h3>}
      {!loaded && <div className={classes.loading}></div>}
    </div>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignUp
        slot="sign-up"
        formFields={[
          { type: "username" },
          { type: "password" },
          { type: "email" },
        ]}
      />
    </AmplifyAuthenticator>
  );
};

export default App;
