import React from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";

interface IProps {
  option: string;
  active: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      height: "3rem",
      transition: "0.3s ease-in",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.secondary.main,
        filter: "brightness(70%)",
      },
    },
    active: {
      color: theme.palette.secondary.main,
      "&::before": {
        position: "absolute",
        content: "''",
        height: "1.6em",
        top: "23.33%",
        left: "-0.75em",
        borderRight: `0.15em solid ${theme.palette.secondary.main}`,
        animation: `$effect 0.3s`,
      },
    },
    "@keyframes effect": {
      "0%": {
        height: "1em",
      },
      "100%": {
        height: "1.6em",
      },
    },
  }),
);

const Option = (props: IProps) => {
  const classes = useStyles();
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.active]: props.active === true,
      })}
    >
      <Typography variant={"h6"}>{props.option}</Typography>
    </div>
  );
};

export default Option;
