import React from "react";
import { createStyles, Divider, makeStyles, Theme } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import MenuItem from "./MenuItem";
interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      padding: "2em",
      height: "100%",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        height: "25vh",
        overflowY: "scroll",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        right: "0px",
        top: "0%",
        width: "0.1875em",
        height: "6em",
        borderRight: `0.1875em solid ${theme.palette.secondary.main}`,
      },
    },
  }),
);

const NavigationPane = (props: IProps) => {
  const classes: Record<"root", string> = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.activeBuilding);
  const options = useSelector((state) => state.buildingsList);
  const handleChange = (option) => {
    dispatch({
      type: "SET_ACTIVE_BUILDING",
      active: option,
    });
    dispatch({
      type: "SET_ACTIVE_SECTION",
      section: option[1],
    });
  };

  const find = (obj) => {
    return obj.find(
      (item) => item["data_name"] === "building_characteristics_building_name",
    ).value;
  };

  return (
    <div className={classes.root}>
      <Divider />
      {options.map((option, i) => {
        return (
          <div key={i} onClick={() => handleChange(option)}>
            <MenuItem
              option={find(option[0].elements)}
              active={find(state[0].elements) === find(option[0].elements)}
            />
            <Divider />
          </div>
        );
      })}
    </div>
  );
};

export default NavigationPane;
