import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
} from "@material-ui/core";

import Bar from "../../../charts/Bar";

interface IProps {
  data: object[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingRight: "2.5rem",
    },
    left: {
      paddingRight: "2.25rem",
      textAlign: "justify",
    },
    titleMargin: {
      marginTop: "1.5rem",
    },
    chartsMargin: {
      marginBottom: "1.5rem",
    },
  }),
);

const EfficiencyMeasuresDetail = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid className={classes.left} item xs={6}>
          <Typography variant={"h5"}>{props.data[4]["label"]}</Typography>
          <Typography variant={"body2"}>{props.data[4]["value"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"h6"}>{props.data[5]["label"]}</Typography>
          <Typography variant={"caption"}>
            {props.data[5]["value"]} €
          </Typography>

          <Typography className={classes.titleMargin} variant={"h6"}>
            {props.data[6]["label"]}
          </Typography>
          <Typography variant={"caption"}>
            {props.data[6]["value"]} kW
          </Typography>

          <Typography className={classes.titleMargin} variant={"h6"}>
            {props.data[7]["label"]}
          </Typography>
          <Typography
            display={"block"}
            className={classes.chartsMargin}
            variant={"caption"}
          >
            {props.data[7]["value"]} €/year
          </Typography>
          <Bar
            quantity={parseInt(props.data[7]["value"], 10)}
            max={Math.max(props.data[7]["value"], props.data[8]["value"])}
            title={"Free-cooling en CPD"}
          />
          <Bar
            quantity={parseInt(props.data[8]["value"], 10)}
            max={Math.max(props.data[7]["value"], props.data[8]["value"])}
            title={props.data[8]["label"]}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default EfficiencyMeasuresDetail;
