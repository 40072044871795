import React from "react";
import { useSelector } from "react-redux";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Link,
} from "@material-ui/core";

import InfoModal from "./modals/InfoModal";

interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "5.5rem",
      backgroundColor: theme.palette.background.default,
      padding: "1.5em 2em",
      [theme.breakpoints.down("xs")]: {
        overflowY: "scroll",
      },
    },
    title: {
      fontFamily: "Times, Regular",
    },
    info: {
      "&:hover": {
        cursor: "pointer",
        filter: "brightness(85%)",
      },
    },
  }),
);

const ContentHeader = (props: IProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  let building = useSelector((state) => state.activeBuilding);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <Typography variant={"h1"} className={classes.title}>
        {
          building[0].elements.find(
            (item) =>
              item.data_name === "building_characteristics_building_name",
          ).value
        }
      </Typography>
      <Typography
        variant={"subtitle1"}
        color={"textSecondary"}
        className={classes.info}
      >
        <Link href="#" onClick={handleClickOpen} color="inherit">
          + Información
        </Link>
      </Typography>
      <InfoModal
        open={open}
        handleClose={handleClose}
        info={building[0].elements}
      />
    </div>
  );
};

export default ContentHeader;
