import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Link,
  Grid,
  Tooltip,
} from "@material-ui/core";

interface IProps {
  key: number;
  type: string;
  label: string;
  value: string;
  photo: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0.4rem",
    },
    h6red: {
      color: theme.palette.secondary.main,
    },
    times: {
      fontFamily: "Times, Regular",
    },
    image: { width: "100%", padding: "0.5rem" },
  }),
);

const ModalItem = (props: IProps) => {
  const classes: Record<
    "root" | "h6red" | "times" | "image",
    string
  > = useStyles();
  if (props.type === "building_characteristics_building_name") {
    return (
      <Grid className={classes.root} item xs={12}>
        <Typography variant={"h1"} className={classes.times}>
          {props.value}
        </Typography>
      </Grid>
    );
  } else if (props.type === "building_characteristics_photo") {
    return (
      <img
        className={classes.image}
        src={
          "https://api.fulcrumapp.com/api/v2/photos/" +
          props.photo[0]["photo_id"]
        }
        alt={props.label}
      />
    );
  } else {
    return (
      <Grid className={classes.root} item xs={12} sm={6}>
        <Typography variant={"h6"} className={classes.h6red}>
          {props.label}
        </Typography>
        <Typography variant={"body1"} className={classes.times}>
          {props.type === "building_characteristics_street" && (
            <Tooltip title="To Google Maps">
              <Link
                href={
                  "https://www.google.com/maps/search/?api=1&query=" +
                  encodeURIComponent(props.value)
                }
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
              >
                {props.value}
              </Link>
            </Tooltip>
          )}
          {props.type !== "building_characteristics_street" && props.value}
        </Typography>
      </Grid>
    );
  }
};
export default ModalItem;
