import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

interface IProps {
  quantity: number;
  max: number;
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "55%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    bar: { width: "75%" },
    quantity: { width: "20%" },
    fullWidthBar: {
      backgroundColor: theme.palette.secondary.main,
      height: "0.5rem",
    },
    secondaryBar: {
      backgroundColor: "#7D4196",
      height: "0.5rem",
    },
    notDisplayed: {
      width: "100%",
      display: "none",
    },
  }),
);

const Bar = (props: IProps) => {
  const classes = useStyles();

  let barWidth: string = "100%";
  if (props.quantity < props.max) {
    barWidth = (props.quantity / props.max) * 100 + "%";
  }

  return (
    <React.Fragment>
      <Typography
        display={"block"}
        className={clsx({
          [classes.notDisplayed]: props.quantity < props.max,
        })}
        variant={"caption"}
      >
        {props.title}
      </Typography>
      <div className={classes.root}>
        <div className={classes.bar}>
          <div
            className={clsx({
              [classes.fullWidthBar]: props.quantity === props.max,
              [classes.secondaryBar]: props.quantity < props.max,
            })}
            style={{ width: barWidth }}
          ></div>
        </div>
        <div className={classes.quantity}>
          <Typography variant={"caption"}>{props.quantity} €</Typography>
        </div>
      </div>
      <Typography
        display={"block"}
        className={clsx({
          [classes.notDisplayed]: props.quantity === props.max,
        })}
        variant={"caption"}
      >
        {props.title}
      </Typography>
    </React.Fragment>
  );
};
export default Bar;
