import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";

import "./index.css";
import { h2020Theme } from "./common/mui";
import App from "./modules/App";
import { reducer } from "./store/reducer";
/*import PDF from "./modules/print/PDF";*/

const store = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(
  /*<React.StrictMode>*/
  <React.Fragment>
    <Provider store={store}>
      <ThemeProvider theme={h2020Theme}>
        <CssBaseline />
        <App />
        {/*<PDF />*/}
      </ThemeProvider>
    </Provider>
  </React.Fragment>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
