import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { createStyles, makeStyles, Theme, Divider } from "@material-ui/core";

import MenuItem from "./MenuItem";

interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      backgroundColor: theme.palette.background.default,
      padding: "1.5em 2em",
    },
  }),
);

const ContentMenu = (props: IProps) => {
  const classes: Record<"root", string> = useStyles();
  const sections = useSelector((state) => state.activeBuilding);
  const state = useSelector((state) => state.activeSection);
  const dispatch = useDispatch();
  const handleChange = (section) => {
    dispatch({
      type: "SET_ACTIVE_SECTION",
      section: section,
    });
  };
  return (
    <div className={classes.root}>
      <Divider />
      {sections.map((section, i) => {
        if (section.data_name !== "building_characteristics") {
          return (
            <div key={i} onClick={() => handleChange(section)}>
              <MenuItem
                option={section.label}
                active={section.data_name === state.data_name}
              />
              <Divider />
            </div>
          );
        } else return null;
      })}
    </div>
  );
};

export default ContentMenu;
