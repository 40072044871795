import { createMuiTheme } from "@material-ui/core/styles";

// For all vars available to config Material-UI theme:
// https://v3.material-ui.com/customization/themes/
export const h2020Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff",
      light: "#0000002B",
      dark: "#0000002B",
      contrastText: "#000000",
    },
    secondary: {
      main: "#E61E28",
      dark: "#7D4196",
    },
    background: {
      default: "#ffffff",
      paper: "#F7F7F9",
    },
    text: {
      primary: "#000000",
      secondary: "#707070",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: "#E61E28",
        borderRadius: "0.625rem",
        boxShadow: "0px 5px 10px #00000029",
        "&:hover": {
          backgroundColor: "#A61E28",
        },
      },
      outlinedPrimary: {
        height: "2.5rem",
        backgroundColor: "transparent",
        borderRadius: "1.25rem",
        "&:hover": {
          backgroundColor: "#0092D1",
          color: "#ffffff",
        },
      },
    },
  },
  typography: {
    fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "1.625rem",
      fontWeight: 500,
      letterSpacing: 0,
    },
    h2: {
      fontSize: "1.438rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.125rem",
      fontWeight: 500,
      letterSpacing: 0.36,
    },
    h5: {
      fontSize: "0.625rem",
      lineHeight: "1.4",
      fontWeight: 700,
    },
    h6: {
      fontSize: "0.71875em",
      fontWeight: 700,
      padding: 0,
      letterSpacing: 0,
    },
    subtitle1: {
      fontSize: "0.71875rem",
    },
    body1: {
      fontWeight: 500,
      fontSize: "inherit",
    },
    body2: {
      fontSize: "0.66rem",
    },
    caption: {
      fontSize: "0.6rem",
      letterSpacing: 0.08,
    },

    button: {
      fontSize: "1rem",
      fontWeight: 700,
      textTransform: "capitalize",
      letterSpacing: 0.32,
    },
  },
});

h2020Theme.shadows[24] = "0px 5px 10px #00000029";
