import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
} from "@material-ui/core";

interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    legend: {
      padding: "0 3.25rem 0 16px",
      marginBottom: "0.5rem",
      alignItems: "center",
    },
    rating: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    break: {
      display: "block",
    },
  }),
);

const AreasHeader = (props: IProps) => {
  const classes = useStyles();
  return (
    <Grid className={classes.legend} container>
      <Grid item xs={5}>
        <Typography variant={"caption"}>Espacio</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>Uso</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>Superficie (m²)</Typography>
      </Grid>
      <Grid item xs={3}>
        <div className={classes.rating}>
          <Typography variant={"caption"}>Heat</Typography>
          <Typography variant={"caption"}>Cold</Typography>
          <div>
            <Typography variant={"caption"}>Above</Typography>
            <Typography className={classes.break} variant={"caption"}>
              grade
            </Typography>
          </div>
          <div>
            <Typography variant={"caption"}>Below</Typography>
            <Typography className={classes.break} variant={"caption"}>
              grade
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
export default AreasHeader;
