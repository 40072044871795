import React from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

interface IProps {
  type: string;
  label: string;
  value: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      padding: " 0.15rem 0",
    },
  }),
);

const SimpleDetailItem = (props: IProps) => {
  const classes: Record<"root", string> = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant={"body2"} style={{ marginRight: 3 }}>
        {props.label}:
      </Typography>

      <Typography variant={"body2"}>{props.value}</Typography>
    </div>
  );
};
export default SimpleDetailItem;
