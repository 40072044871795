import React from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "4rem",
      boxShadow: theme.shadows[24],
      padding: "0 2em",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      zIndex: 1,
    },
    leftSide: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    logo: {
      maxHeight: "1em",
    },
    title: {
      fontFamily: "Times",
    },
  }),
);

const TopBar = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        variant={"h2"}
        color={"textPrimary"}
        className={classes.title}
      >
        Auditoría energética
      </Typography>
      <img src="/Arup_Red_RGB.svg" alt={"Arup logo"} className={classes.logo} />
    </div>
  );
};

export default TopBar;
