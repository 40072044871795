import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Bar } from "react-chartjs-2";

interface IProps {
  values: object;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "14rem",
      backgroundColor: theme.palette.background.default,
    },
  }),
);

const data = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      backgroundColor: "#E61E28",
      borderColor: "#E61E28",
      borderWidth: 1,
      hoverBackgroundColor: "#E61E28",
      hoverBorderColor: "#E61E28",
      data: [120, 81, 90, 45, 56, 55, 61, 80, 83, 76, 99, 100],
    },
  ],
};
const options = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
  layout: {
    padding: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

const BarChart = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Bar data={data} options={options} width={100} height={50} />
    </div>
  );
};

export default BarChart;
