import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

interface IProps {
  filled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outlined: {
      height: "0.5rem",
      width: "0.5rem",
      borderRadius: "0.25rem",
      border: `0.5px solid ${theme.palette.secondary.main}`,
      marginRight: "0.5rem",
    },
    filled: {
      height: "0.5rem",
      width: "0.5rem",
      borderRadius: "0.25rem",
      backgroundColor: theme.palette.secondary.main,
      marginRight: "0.5rem",
    },
  }),
);

const Circle = (props: IProps) => {
  const classes = useStyles();
  return (
    <div
      className={clsx({
        [classes.filled]: props.filled === true, //always applies
        [classes.outlined]: props.filled === false, //only when open === true
      })}
    ></div>
  );
};
export default Circle;
