import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import SimpleDetailItem from "../../SimpleDetailItem";

interface IProps {
  data: object[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  }),
);

const SpaceFunctionDetail = (props: IProps) => {
  const classes: Record<"root", string> = useStyles();
  return (
    <div className={classes.root}>
      {props.data.map((element, i) => {
        if (element["value"] && i > 0) {
          return (
            <React.Fragment key={i}>
              <SimpleDetailItem
                type={element["type"]}
                label={element["label"]}
                value={element["value"]}
              />
            </React.Fragment>
          );
        } else return null;
      })}
    </div>
  );
};
export default SpaceFunctionDetail;
