import { Action, Reducer } from "redux";
import { Actions } from "./actions";

export interface IBuildings {
  buildingsList: Object[];
  activeBuilding: object;
  activeSection: Object;
}

const initialState: IBuildings = {
  buildingsList: [],
  activeBuilding: {},
  activeSection: {},
};

export const reducer: Reducer<IBuildings> = (
  state = initialState,
  incomingAction: Action,
) => {
  const action = incomingAction as Actions;
  switch (action.type) {
    case "SET_BUILDINGS_LIST":
      return { ...state, buildingsList: action.buildingsList };
    case "SET_ACTIVE_BUILDING":
      return { ...state, activeBuilding: action.active };
    case "SET_ACTIVE_SECTION":
      return { ...state, activeSection: action.section };
    default:
      return state;
  }
};
