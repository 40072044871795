import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
} from "@material-ui/core";

import GalleryModal from "../modals/GalleryModal";
import SimpleDetailItem from "./SimpleDetailItem";

interface IProps {
  key: number;
  title: string;
  item: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { width: "100%" },
    info: {
      marginTop: "0.6em",
    },
    picture: {
      width: "9rem",
      height: "9rem",
      cursor: "pointer",
    },
  }),
);

const ComplexDetailItem = (props: IProps) => {
  const classes: Record<any, string> = useStyles();

  const [state, setState] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (i: number) => {
    setState(i);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let gallery: string[] = [];
  let rawGallery = props.item.find(
    (item) => item["type"] === "PhotoField" && item["value"],
  );
  if (rawGallery) {
    rawGallery["value"].forEach((photo) => {
      gallery.push(
        "https://api.fulcrumapp.com/api/v2/photos/" + photo.photo_id,
      );
    });
  } else {
    gallery.push("no_image.png");
  }
  return (
    <React.Fragment>
      <Grid className={classes.root} item sm={12} md={6}>
        <Typography variant={"h6"}>{props.title}</Typography>
        <div className={classes.info}>
          {props.item.map((item, i) => {
            if (item["value"] && item["type"] !== "PhotoField") {
              if (Array.isArray(item["value"])) {
                item["value"] = item["value"].join(", ");
              }
              return (
                <React.Fragment key={i}>
                  <SimpleDetailItem
                    type={item["type"]}
                    label={item["label"]}
                    value={item["value"]}
                  />
                </React.Fragment>
              );
            } else return null;
          })}
        </div>
      </Grid>
      <Grid container item sm={12} md={6} justify="flex-end">
        {gallery.map((photo, i) => {
          if (i < 2) {
            return (
              <Grid key={i} item sm={6} onClick={() => handleClickOpen(i)}>
                <img className={classes.picture} alt="gallerypic" src={photo} />
              </Grid>
            );
          } else return null;
        })}
      </Grid>
      <GalleryModal
        index={state}
        open={open}
        handleClose={handleClose}
        images={gallery}
      />
    </React.Fragment>
  );
};
export default ComplexDetailItem;
