import React from "react";

import EfficiencyMeasuresAccordion from "./summaries/EfficiencyMeasuresAccordion";
import RegularAccordion from "./summaries/RegularAccordion";
import AreasAccordion from "./summaries/AreasAccordion";

interface IProps {
  data_name: string;
  data: object;
  title: string;
}

const SummaryHandler = (props: IProps) => {
  if (props.data_name === "energy_efficiency_measures") {
    return (
      <EfficiencyMeasuresAccordion title={props.title} data={props.data} />
    );
  } else if (props.data_name === "area") {
    return <AreasAccordion title={props.title} data={props.data} />;
  } else {
    return <RegularAccordion title={props.title} />;
  }
};
export default SummaryHandler;
