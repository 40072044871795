import React from "react";

import EfficiencyMeasuresHeader from "./headers/EfficiencyMeasuresHeader";
import AreasHeader from "./headers/AreasHeader";
import BuildingEnvelopeHeader from "./headers/BuildingEnvelopeHeader";

interface IProps {
  data_name: string;
  elements: Object[];
}

const HeaderHandler = (props: IProps) => {
  if (props.data_name === "energy_efficiency_measures") {
    return <EfficiencyMeasuresHeader />;
  } else if (props.data_name === "area") {
    return <AreasHeader />;
  } else if (props.data_name === "building_envelope_characteristics") {
    return <BuildingEnvelopeHeader elements={props.elements} />;
  } else {
    return null;
  }
};
export default HeaderHandler;
