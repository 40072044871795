import React from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import SimpleDetailItem from "../../SimpleDetailItem";

interface IProps {
  elements: Object[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "1.6rem",
      paddingLeft: "1rem",
    },
    title: {
      marginBottom: "1rem",
    },
  }),
);

const BuildingEnvelopeHeader = (props: IProps) => {
  const classes: Record<"root" | "title", string> = useStyles();
  if (props.elements.find((item) => item["value"])) {
    return (
      <div className={classes.root}>
        <Typography variant={"h6"} className={classes.title}>
          Characteristics
        </Typography>

        {props.elements &&
          props.elements.map((option, i) => {
            if (
              !["Repeated", "Repeatable", "Section"].includes(option["type"]) &&
              "value" in option
            ) {
              return (
                <SimpleDetailItem
                  key={i}
                  type={option["type"]}
                  label={option["label"]}
                  value={option["value"]}
                />
              );
            } else return null;
          })}
      </div>
    );
  } else
    return (
      <Typography variant={"h6"} className={classes.title}>
        Data Unavailable
      </Typography>
    );
};
export default BuildingEnvelopeHeader;
