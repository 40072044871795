import React from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";

import NavigationPane from "./NavigationPane";
import Content from "./Content";

interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      flex: "1",
      display: "flex",
      flexDirection: "column",
      padding: "2em",
      backgroundColor: theme.palette.background.paper,
    },
    grid: {
      height: "100%",
      flex: "1",
    },
  }),
);

const Wrapper = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.grid} alignItems="stretch">
        <Grid item xs={12} md={3}>
          <NavigationPane />
        </Grid>
        <Grid container item xs={12} md={9} direction="column">
          <Content />
        </Grid>
      </Grid>
    </div>
  );
};

export default Wrapper;
