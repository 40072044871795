import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
} from "@material-ui/core";

import BarChart from "../../../charts/BarChart";

interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "inline-flex",
    },
    split: {
      width: "50%",
    },
    title: {
      marginBottom: "1rem",
    },
    info: {
      marginTop: "0.25rem",
      "&:hover": {
        cursor: "pointer",
        filter: "brightness(85%)",
      },
    },
  }),
);

const DetailsCharts = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Typography className={classes.title} variant={"h6"}>
            Electricidad
          </Typography>
          <BarChart values={{}} />
          <Typography
            variant={"body2"}
            color={"textSecondary"}
            className={classes.info}
          >
            + Información
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className={classes.title} variant={"h6"}>
            Gas natural
          </Typography>
          <BarChart values={{}} />
          <Typography
            variant={"body2"}
            color={"textSecondary"}
            className={classes.info}
          >
            + Información
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
export default DetailsCharts;
