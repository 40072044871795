import React from "react";
import { Typography } from "@material-ui/core";

interface IProps {
  title: string;
}

const RegularAccordion = (props: IProps) => {
  return <Typography variant={"h6"}>{props.title}</Typography>;
};
export default RegularAccordion;
