import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ComplexDetailItem from "../../ComplexDetailItem";

interface IProps {
  data: object[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    picture: {
      maxWidth: "9rem",
    },
    innerAccordion: {
      width: "95%",
      marginLeft: "auto",
      marginRight: "0px",
    },
    accordion: {
      backgroundColor: theme.palette.background.default,
      boxShadow: "none",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
    innerAccordionTitle: {
      color: "#707070",
    },
    elements: { display: "inline" },
    container: {
      width: "100%",
      paddingLeft: "1rem",
    },
  }),
);

const EquipmentDetail = (props: IProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {props.data.map((element, i) => {
          if (element["type"] === "Repeatable" && element["elements"]) {
            return (
              <div key={element["key"]} className={classes.innerAccordion}>
                <Accordion
                  square={true}
                  className={classes.accordion}
                  expanded={expanded === `panel${i}`}
                  onChange={handleChange(`panel${i}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${i}`}
                    id={`panel${i}`}
                  >
                    <Typography
                      variant={"h6"}
                      className={classes.innerAccordionTitle}
                    >
                      {element["label"]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.elements}>
                    {element["elements"].map((elem, j) => {
                      return (
                        <Grid key={j} container className={classes.container}>
                          <ComplexDetailItem
                            title={elem["label"]}
                            key={j}
                            item={elem["elements"]}
                          />
                        </Grid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          } else return null;
        })}
      </Grid>
    </div>
  );
};
export default EquipmentDetail;
