import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

interface IProps {
  open: boolean;
  handleClose: () => void;
  images: string[];
  index: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    floatingButton: {
      float: "right",
      color: theme.palette.secondary.main,
    },
    modalContent: {
      padding: "1.2rem 1.4rem 3.6rem 1.4rem",
    },
    image: {
      height: "55vh",
      maxWidth: "80vw",
    },
    gallery: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    iconHover: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.4rem",
      borderRadius: "1.2rem",
      "&:hover": {
        cursor: "pointer",
        transition: "0.2s",
        backgroundColor: theme.palette.primary.light,
      },
    },
    indicator: {
      textAlign: "center",
    },
  }),
);

const GalleryModal = (props: IProps) => {
  React.useEffect(() => {
    setState(props.index);
  }, [props.index]);
  const [state, setState] = React.useState(props.index);

  const classes: Record<
    | "floatingButton"
    | "modalContent"
    | "image"
    | "gallery"
    | "iconHover"
    | "indicator",
    string
  > = useStyles();
  const controlsHandler = (e) => {
    if (e === "back") {
      state > 0 ? setState(state - 1) : setState(props.images.length - 1);
    } else if (e === "forward") {
      state < props.images.length - 1 ? setState(state + 1) : setState(0);
    }
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={props.open}
      onClose={props.handleClose}
      PaperProps={{
        style: { borderRadius: 0 },
      }}
    >
      <div className={classes.modalContent}>
        <IconButton
          onClick={props.handleClose}
          className={classes.floatingButton}
          aria-label="Close modal"
        >
          <CancelIcon />
        </IconButton>
        <div className={classes.gallery}>
          <div
            className={classes.iconHover}
            onClick={() => controlsHandler("back")}
          >
            <ChevronLeftIcon />
          </div>
          <img
            className={classes.image}
            alt="gallerypic"
            src={props.images[state]}
          />
          <div
            className={classes.iconHover}
            onClick={() => controlsHandler("forward")}
          >
            <ChevronRightIcon />
          </div>
        </div>
        <div className={classes.indicator}>
          <Typography variant={"caption"}>
            {state + 1}/{props.images.length}
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};
export default GalleryModal;
