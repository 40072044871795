import React from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";

import ContentHeader from "./ContentHeader";
import ContentMenu from "./ContentMenu";
import ContentDetails from "./ContentDetails";

interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerPadding: {
      flex: "0 1 auto",
      paddingBottom: "2em",
    },
    stretchContent: {
      flex: "1 1 auto",
    },
    menuPadding: {
      [theme.breakpoints.up("sm")]: {
        paddingRight: "2em",
      },
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "2em",
      },
    },
  }),
);

const Content = (props: IProps) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.headerPadding}>
        <ContentHeader />
      </Grid>
      <Grid item xs={12} container className={classes.stretchContent}>
        <Grid item xs={12} sm={4} className={classes.menuPadding}>
          <ContentMenu />
        </Grid>
        <Grid item xs={12} sm={8}>
          <ContentDetails />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Content;
