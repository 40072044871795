import React from "react";
import { createStyles, makeStyles, Theme, Grid } from "@material-ui/core";

import GalleryModal from "../../../modals/GalleryModal";

interface IProps {
  data: object[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    header: {
      width: "100%",
      height: "2.5rem",
      lineHeight: "2.5rem",
    },
    picture: {
      width: "9rem",
      height: "9rem",
      cursor: "pointer",
    },
    grid: {
      textAlign: "center",
      justifyContent: "space-around",
    },
  }),
);

const DetailSpaces = (props: IProps) => {
  const classes = useStyles();

  const [state, setState] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (i: number) => {
    setState(i);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const rawGallery: Object | undefined = props.data.find(
    (element) => element["type"] === "PhotoField" && element["value"],
  );

  if (rawGallery) {
    const gallery: string[] = [];
    rawGallery["value"].forEach((photo) => {
      gallery.push(
        "https://api.fulcrumapp.com/api/v2/photos/" + photo.photo_id,
      );
    });
    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container spacing={2}>
          {gallery.map((photo, i) => {
            return (
              <Grid key={i} item onClick={() => handleClickOpen(i)}>
                <img className={classes.picture} alt="gallerypic" src={photo} />
              </Grid>
            );
          })}
        </Grid>
        <GalleryModal
          index={state}
          open={open}
          handleClose={handleClose}
          images={gallery}
        />
      </div>
    );
  } else return <div>No photos available</div>;
};
export default DetailSpaces;
