import React from "react";

import ChartsDetail from "./tabs/ChartsDetail";
import EquipmentDetail1 from "./tabs/EquipmentDetail1";
import EquipmentDetail2 from "./tabs/EquipmentDetail2";
import EfficiencyMeasuresDetail from "./tabs/EfficiencyMeasuresDetail";
import AreasDetail from "./tabs/AreasDetail";
import SpaceFunctionDetail from "./tabs/SpaceFunctionDetail";
import BuildingEnvelopeDetail from "./tabs/BuildingEnvelopeDetail";

interface IProps {
  data: object[];
  data_name: string;
}

const DetailHandler = (props: IProps) => {
  switch (props.data_name) {
    case "equipment_inventory":
      if (props.data && props.data.length === 1 && props.data[0]["elements"]) {
        return <EquipmentDetail2 data={props.data[0]["elements"]} />;
      } else {
        return <EquipmentDetail1 data={props.data} />;
      }
    case "energy_efficiency_measures":
      return <EfficiencyMeasuresDetail data={props.data} />;
    case "area":
      return <AreasDetail data={props.data} />;
    case "space_function_breakdown":
      return <SpaceFunctionDetail data={props.data} />;
    case "building_envelope_characteristics":
      return <BuildingEnvelopeDetail data={props.data} />;
    case "charts":
      return <ChartsDetail />;
    default:
      return null;
  }
};
export default DetailHandler;
