import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
} from "@material-ui/core";

interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    legend: {
      padding: "0 3.25rem 0 1rem",
      marginBottom: "0.5rem",
    },
  }),
);

const EfficiencyMeasuresHeader = (props: IProps) => {
  const classes = useStyles();
  return (
    <Grid className={classes.legend} container>
      <Grid item xs={6}>
        <Typography variant={"caption"}>Título</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>Aplica/No aplica</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>Coste</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>Prioridad</Typography>
      </Grid>
    </Grid>
  );
};
export default EfficiencyMeasuresHeader;
